
import { defineComponent, ref, watch, onMounted , PropType} from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { useBus } from "@/bus.ts";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "BeneficiaryModal",
  props: {
    data: {
      type: Object as PropType<{ id: number; district_name: string; district_color: string }>,
      required: true,
    },
  },
  setup(props) {
    const { bus } = useBus();

    const beneficiaryList = ref([]);
    const upazilaList = ref([]);
    const districtName = ref<string | null>(null);
    const districtColor = ref<string | null>(null);

    const show = (id) => {
      const beneficiaryProfileRoute = `/beneficiary-profile/${id}`;
      window.open(beneficiaryProfileRoute, "_blank");
    };

    const loadBeneficiaryList = async (upazila_id='') => {
      try {

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_BENEFICIARY_LIST,
            {
              ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") != 'null'
                  ? VueCookieNext.getCookie("_ia_partner_id")
                  : 0,
              geo_district_id : props.data.id,
              geo_upazila_id : upazila_id,
            }
        );

        beneficiaryList.value = response.data.data;
        districtName.value = props.data.district_name;
        districtColor.value = props.data.district_color;

      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const loadUpazilaList = async () => {
      try {

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_UPAZILA_LIST,
            {
              geo_district_id : props.data.id,
            }
        );

        upazilaList.value = response.data.data;

      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    watch(() => props.data, (newData) => {
      if(props.data){
        loadBeneficiaryList();
        loadUpazilaList();
      }
    });

    // Load data on component mount
    onMounted(() => {
      if(props.data){
        loadBeneficiaryList();
        loadUpazilaList();
      }
    });

    return { loadBeneficiaryList, beneficiaryList, upazilaList, show , districtName, districtColor};
  },
});
