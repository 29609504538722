
import { defineComponent, ref, onMounted } from "vue";
import BeneficiaryModal from "@/components/modals/forms/beneficiary/BeneficiaryModal.vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { useBus } from "@/bus.ts";
import {DrawerComponent} from "@/assets/ts/components";

export default defineComponent({
  name: "EventList",
  components: {
    BeneficiaryModal,
  },
  setup() {
    const { bus } = useBus();
    const divisionList = ref<any[]>([]);
    const districtList = ref([]);
    const totalBeneficiaryCount = ref(0);
    const showData = ref<{ id: number; district_name: string; district_color: string } | null>(null);
    const zoomFactor = ref(1);
    const originalViewBox = '0 700 1600 1800';
    // const viewBox = ref(originalViewBox);

    const isDragging = ref(false);
    const startX = ref(0);
    const startY = ref(0);
    const startViewBox = ref("");
    const viewBox = ref("");

    const startDrag = (event) => {
      isDragging.value = true;
      startX.value = event.clientX;
      startY.value = event.clientY;
      startViewBox.value = viewBox.value;
      document.body.style.cursor = 'grabbing';
    };

    const endDrag = () => {
      isDragging.value = false;
      document.body.style.cursor = 'grab';
    };

    const drag = (event) => {
      if (!isDragging.value) return;
      const dx = event.clientX - startX.value;
      const dy = event.clientY - startY.value;
      const [x, y, width, height] = startViewBox.value.split(" ").map(parseFloat);
      viewBox.value = `${x - dx} ${y - dy} ${width} ${height}`;
    };


    const zoom = (factor) => {
      zoomFactor.value *= factor;
      updateViewBox();
    };

    const zoomIn = () => {
      zoom(1.2);
    };

    const zoomOut = () => {
      zoom(0.8);
    };

    const updateViewBox = () => {
      const originalValues: number[] = originalViewBox.split(' ').map(parseFloat);
      let [x, y, width, height]: number[] = originalValues;

      width /= zoomFactor.value;
      height /= zoomFactor.value;

      // Adjust the x-coordinate to keep the zoom centered
      x = (originalValues[2] - width) / 2;

      viewBox.value = `${x} ${y} ${width} ${height}`;
    };

    const show = (id, district_name, district_color) => {
      showData.value = {
        id,
        district_name,
        district_color,
      };
    };

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
            apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionList.value = response.data.data;
        totalBeneficiaryCount.value = divisionList.value.reduce((total, division) => total + division.beneficiaries_count, 0);

      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DISTRICT_LIST,
          {}
        );

        districtList.value = response.data.data;

      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();
      getDivision();
      zoomIn();
      zoomIn();

      bus.on("loadData", async () => {
        loadData();
        zoomIn();
        zoomIn();
      });
    });

    return {
      showData,
      divisionList,
      districtList,
      totalBeneficiaryCount,
      show,
      zoom,
      viewBox,
      zoomIn,
      zoomOut,
      startDrag,
      endDrag,
      drag,
    };
  },
});
